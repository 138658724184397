<template>
  <div>
    <el-button type="primary" size="mini" class="editor-typesetting" @click="dialogVisible=true">
      选择图标
    </el-button>
    <el-dialog :visible.sync="dialogVisible">
      <div class="main">
        <div class="body">
          <div v-for="item in iconList" :key="item.id" class="pic-item" @click="comfirm(item)">
            <el-image :src="item.url" />
          </div>
        </div>
        <div class="foot">
          <el-button @click="dialogVisible=false">取消</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      photoSelected: [],
      iconList: [
        { id: 1, name: '图片1', url: 'image/hot.png' },
        { id: 2, name: '图片2', url: 'image/hot.png' },
        { id: 3, name: '图片3', url: 'image/hot.png' },
        { id: 4, name: '图片4', url: 'image/hot.png' },
        { id: 5, name: '图片5', url: 'image/hot.png' },
        { id: 6, name: '图片6', url: 'image/hot.png' }
      ]
    }
  },
  methods: {
    comfirm(item) {
      this.$emit('selected', item.url)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  .body{
    height: 150px;
    overflow: auto;
    .pic-item{
      display: inline-block;
      width:10%;
      padding: 5px;
      cursor: pointer;
    }
  }

  .foot{
    text-align: right;
    padding-top: 10px;
  }
}

.photogallery{
  position: relative;
  ::v-deep.el-checkbox__input{
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
