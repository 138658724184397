<template>
  <div class="box">
    <div class="see" v-if="show">
      <!-- <navi-gation /> -->
      <el-row :gutter="4" class="mt15" style="display: flex;padding-left: 10px;padding-right: 10px;margin-top:10px">
        <select-input :options="options1" @selectSearchChange="selectSearchChange" @searchList="searchList"></select-input>
      </el-row>
      <el-row :gutter="3" style="padding: 0px 0 0 10px"> 
        <el-button-group>
          <el-button type="primary" size="small" @click="addlist">{{$t('public.New')}}</el-button>
          <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="deleteList(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
        </el-button-group>
      </el-row>
      <div class="tablebox" style="padding-left: 10px; padding-right: 10px">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" stripe ref="multipleTable" :data="tableData" tooltip-effect="dark" :row-key="getRowKeys" style="width: 100%; margin-top: 10px" :default-sort="{ prop: 'update_time', order: 'descending' }" @selection-change="handleSelectionChange" @sort-change="sort" class="tableClass">
          <el-table-column align="center" type="selection" :reserve-selection="true" width="55" height="80">
          </el-table-column>
          <el-table-column :label="$t('MobileOperations.UserHelpConfiguration.UserHelpList.title')" width="180" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column prop="HELP_NAME" sortable="custom" :label="$t('public.ApplicationName')" width="180">
            <template slot-scope="scope">
              <div>
                {{ scope.row.helpName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CREATOR" sortable="custom" :label="$t('MobileOperations.UserHelpConfiguration.UserHelpList.creator')" show-overflow-tooltip width="180">
            <template slot-scope="scope">
              <div>
                {{ scope.row.creator }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="GROUP_ID" sortable="custom" :label="$t('public.Section')" show-overflow-tooltip width="180">
            <template v-slot="{ row }">
              <span v-if="row.partment">
                {{ row.partment.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column sortable="custom" prop="update_time" :label="$t('MobileOperations.UserHelpConfiguration.UserHelpList.UpdateTime')" show-overflow-tooltip width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                {{ scope.row.updateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
              <img :title="$t('public.Editor')" @click="changeedit(row)" src="../../assets/icon_write.png" alt="" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
              <span class="action_icon">
              <img :title="$t('public.Delete')" @click="deleted(row)" src="../../assets/icon_del.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
         
      </div>
    </div>
    <div class="show" v-else-if="!show"> 
      <div style="padding-left: 87px">
        <el-form :model="queryObj" :rules="rules" ref="ruleForm" label-width="100px" class="tableClass">
          <el-form-item :label="$t('MobileOperations.UserHelpConfiguration.AddUserHelp.SelectiveApplication')" prop="helpName" style="padding-top: 20px">
            <el-select v-model="queryObj.helpName" :disabled="flagStatus" :placeholder="$t('MobileOperations.UserHelpConfiguration.AddUserHelp.PleaseSelectiveApplication')">
              <el-option v-for="item in ruleForm.region1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.UserHelpConfiguration.AddUserHelp.HelpTitle')" prop="title" style="width: 80%; padding: 0 0 0 0">
            <el-input :disabled="flagStatus" v-model="queryObj.title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.UserHelpConfiguration.AddUserHelp.HelpContent')" style="width: 80%" prop="content">
            <!-- <Tinymce ref="content" v-model="queryObj.content" :photo-gallery="true" :video-gallery="true" :height="300" /> -->
            <tinymce v-model="queryObj.content" :photo-gallery="true" :height="500" class="tinymce" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">{{$t('MobileOperations.UserHelpConfiguration.AddUserHelp.publish')}}</el-button>
            <!-- <el-button @click="resetForm('ruleForm')" size="small">{{$t('MobileOperations.UserHelpConfiguration.AddUserHelp.Cancel')}}</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import selectInput from "@/components/selectInput"
import Tinymce from '@/components/tinymce2/FullFeatureIndex'
import dialogInfo from '@/components/promptMessage/index'
import naviGation from '@/components/hearder/index'
// import Tinymce from '../../components/Tinymce/index.vue'
import pagination from '../../components/pagination/page.vue'
export default {
  components: {
    selectInput,
    Tinymce,
    pagination,
    naviGation,
    dialogInfo
  },
  data() {
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      groupFullNameShowByIndex: '', //用户部门截取的下标
      visibaelFlag: false,
      titleInfo: '',
      placeholder: '标题',
      querayObj: {
        input: '',
        title: '标题',
        helpName: '',
        concent: ''
      },
      selectVal:{
        deviceName:'',
        InputText:""
      },
      options1: [
        {
          value: 0,
          label: '标题'
        },
        {
          value: 1,
          label: '应用名称'
        }
      ],
      // 弹框删除
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      flagStatus: false,
      seccess: '',
      // 遮罩全选
      isCheckShow: false,
      // 显示隐藏
      show: true,
      // 状态
      options: [],
      value: '',
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
        orderColume: 'update_time', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      // input模糊查询
      input: '',
      // table
      tableData: [],
      queryObj: {},
      // tabele参数
      currentPage: 1,
      pageSize: 10,
      orderColume: 'update_time',
      orderRule: 'DESC',
      title: ' ',
      helpName: ' ',
      ids: [],
      selectChange:0,
      ruleForm: {
        name: '',
        region: '',
        region1: [],
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        helpName: ''
      },
      multipleSelection: [],
      delarr: [], //存放要删除的数据
      form: {
        publishingPageContent: '',
        newsNum: null
      },
      rules: {
        title: [
          { required: true, message: '请输入标题名称', trigger: 'blur' },
          {
            min: 3,
            max: 30,
            message: '长度在 3 到 30 个字符',
            trigger: 'blur'
          }
        ],
        helpName: [
          { required: true, message: '请选择应用', trigger: 'change' }
        ],
        content: [{ required: true, message: '请输入内容', trigger: 'change' }]
      },
      flagShow: true
    }
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  created() {
    // table
    this.tablelist()
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {
    selectSearchChange(val){
      this.selectChange = val.deviceName
      console.log(this.selectChange)
    },
    getRowKeys(row) {
      return row.id
    },

    handleClose() {
      this.visibaelFlag = false
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page
      this.page.pageSize = limit
      // 调用查询方法
      this.tablelist(this.selectVal)
    },
    changeName(item) {
      if (item === '应用名称') {
        this.flagShow = false
        this.querayObj.input = ''
      } else {
        this.flagShow = true
        this.querayObj.helpName = ''
      }
      this.placeholder = item
    },
    // 模糊搜索
    async searchList(val) {
      console.log(val.deviceName)
      this.selectVal = val
      this.page.currentPage = 1
      var param = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume, //排序字段
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        helpName: val.deviceName == 1?val.InputText:'',
        title: val.deviceName == 0?val.InputText:''
      }
      const res = await this.$axios.post(
        '/httpServe/clientHelp/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
      this.page.total = res.data.total
    },
    async deleteList(onj) {
      this.$confirm(this.$t('MobileOperations.UserHelpConfiguration.UserHelpList.DELMessage'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel') ,
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          let params = []
          this.multipleSelection.forEach((item, index) => {
            params.push(item.id + '')
          })
          var param = {
            ids: params //注：新增无id,修改时填id???
          }
          this.$axios.post('/httpServe/clientHelp/delete', param, true)
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
          this.page.currentPage = 1
          this.tablelist()
        })
        .catch(() => {
          return false
        })
    },
    handleSelectionChange(obj) {
      this.multipleSelection = obj
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    //全 {选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    //当前页全选
    curSelection() {
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.disabled = false;
    //   this.$refs.multipleTable.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },

    //排序
    sort(column) {
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.tablelist()
    },
    // /clientHelp/getDataInfo
    async tablelist(val) {
      var param = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume, //排序字段
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        helpName: val?val.deviceName == 1?val.InputText:this.querayObj.helpName:'',
        title: val?val.deviceName == 0?val.InputText:this.querayObj.input:""
      }
      const res = await this.$axios.post(
        '/httpServe/clientHelp/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
      this.page.total = res.data.total
    },
    // 下拉框  调取用户列表接口
    async selectlist() {
      var param = {}
      var arr = []
      const res = await this.$axios.post(
        '/httpServe/appStore/selectList',
        param,
        true
      )
      res.data.dataInfo.forEach((item) => {
        arr.push({ lable: item, value: item })
      })

      this.ruleForm.region1 = arr
    },
    // 新增按钮配置  保存----
    async save() {
      if (this.queryObj.ids) {
        this.SaveEdit()
      } else {
        this.SaveEditAdd()
      }
    },
    async SaveEditAdd() {
      //新增
      var param = {
        ids: [],
        title: this.queryObj.title,
        helpName: this.queryObj.helpName,
        helpType: '',
        partment: '',
        content: this.queryObj.content
      }
      const res = await this.$axios.post(
        '/httpServe/clientHelp/save',
        param,
        true
      )
      console.log(res)
      if (res.code == 500) {
        this.$message({
          message: this.$t('MobileOperations.UserHelpConfiguration.UserHelpList.SaveMessage'),
          type: 'error',
          offset: 100
        })
      }
      if (res.code == 101) {
      }
      if (res.code === 200) {
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.show = true
        this.tablelist()
      }
    },
    async SaveEdit() {
      var param = {
        ids: this.queryObj.ids, //注：新增无id,修改时填id
        title: this.queryObj.title,
        helpName: this.queryObj.helpName,
        helpType: null,
        partment: null,
        content: this.queryObj.content
      }
      const res = await this.$axios.post(
        '/httpServe/clientHelp/save',
        param,
        true
      )
      if (res.code === 200) {
        this.$message({
          message: this.$t('MobileOperations.UserHelpConfiguration.UserHelpList.ReleaseSuccess'),
          type: 'success',
          offset: 100
        })
        this.show = true
        this.tablelist()
      }
    },
    async deleted(val) {
      this.$confirm(this.$t('MobileOperations.UserHelpConfiguration.UserHelpList.DELMessage'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel') ,
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.getArtResultList(val)
        })
        .catch(() => {
          return false
        })
    },
    async getArtResultList(val) {
      let data = []
      data.push(val.id)
      var param = {
        ids: data //注：新增无id,修改时填id???
      }
      const res = await this.$axios.post(
        '/httpServe/clientHelp/delete',
        param,
        true
      )
      if (res.code === 200) {
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
        this.page.currentPage = 1
        this.tablelist()
      }
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false
    },
    // 新增
    addlist() {
      this.flagStatus = false
      this.show = false
      this.selectlist()
      this.queryObj = {}
    },
    // 编辑
    changeedit(row) {
      this.flagStatus = true
      this.selectlist()
      let data = []
      data.push(row.id)
      this.queryObj.ids = data
      this.show = false
      // // 回显
      this.queryObj.helpName = row.helpName
      this.queryObj.title = row.title
      this.queryObj.content = row.content
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$set(this.queryObj, 'helpName', '')
      this.$set(this.queryObj, 'title', '')
      this.queryObj.content = ''
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 1200px;
}
// 查询
.header {
  width: 100%;
  display: flex;
  height: 35px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-top: 10px;
  .seach {
    display: flex;
    justify-content: space-between;
    .el-input {
      padding-left: 5px;
    }
    .el-button {
      height: 40px;
      margin-left: 2px;
    }
  }
}

// 处理按钮
.btn {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  .el-button {
    // color: rgb(76, 184, 247);
    font-weight: 900;
  }
}
.el-form-item__label {
  padding: 0 0 0 0 !important;
}
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 178px;
  left: 1%;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    list-style: none;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
