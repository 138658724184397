<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <editor v-model="myValue"  :init="init" @onClick="onClick" />
    <div class="editor-custom-btn-container">
      <!-- <photo-gallery-select v-if="photoGallery" style="display:inline-block;margin-right:5px;" @selected="photoGallerySelected" /> -->
      <icon-gallery-select v-if="iconGallery" style="display:inline-block;margin-right:5px;" @selected="iconGallerySelected" />
      <el-button v-if="typesetting" type="primary" size="mini" class="editor-typesetting" @click="handlerTypesetting">一键排版</el-button>
    </div>
  </div>
</template>

<script>
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入不显示
import '../../../public/tinymce/icons/default/icons.min.js' // 不引入所有的icon样式不出现
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist' // 高级列表
import 'tinymce/plugins/anchor' // 锚点
import 'tinymce/plugins/autolink' // 自动链接
import 'tinymce/plugins/autoresize'// 编辑器自适应
import 'tinymce/plugins/autosave' // 自动存稿
// import 'tinymce/plugins/bbcode' // bbcode 会替换掉原本的html标签，如：[b] [a]
import 'tinymce/plugins/charmap' // 特殊字符
import 'tinymce/plugins/code' // 编辑源码
// import 'tinymce/plugins/codesample' // 代码示例编辑框
import 'tinymce/plugins/directionality' // 文字方向
// import 'tinymce/plugins/emoticons' // 表情插件
// import 'tinymce/plugins/emoticons/js/emojis'// 表情文件
import 'tinymce/plugins/fullpage'// 编辑元数据和文档属性，包含title，keywords，description和文档编码charset
import 'tinymce/plugins/fullscreen' // 全屏
import 'tinymce/plugins/help' // 帮助
import 'tinymce/plugins/hr' // 水平分割线
import 'tinymce/plugins/image' // 插入编辑图片
import 'tinymce/plugins/imagetools' // 图片编辑工具
import 'tinymce/plugins/importcss' // 引入css
import 'tinymce/plugins/insertdatetime' // 插入日期时间
// import 'tinymce/plugins/legacyoutput' // 使用html4规范
import 'tinymce/plugins/link' // 超链接
import 'tinymce/plugins/lists' // 列表插件
// import 'tinymce/plugins/media' // 插入编辑媒体
import 'tinymce/plugins/nonbreaking' // 插入不间断空格
import 'tinymce/plugins/noneditable' // 不可编辑元素
import 'tinymce/plugins/pagebreak' // 插入分页符
// import 'tinymce/plugins/paste' // 粘贴插件
import 'tinymce/plugins/preview' // 预览
import 'tinymce/plugins/print' // 打印
import 'tinymce/plugins/quickbars' // 快速工具栏
import 'tinymce/plugins/save' // 保存
import 'tinymce/plugins/searchreplace' // 查找替换
import 'tinymce/plugins/spellchecker' // 拼写检查
import 'tinymce/plugins/tabfocus' // 切入切出
import 'tinymce/plugins/table' // 表格插件
import 'tinymce/plugins/template' // 内容模板
import 'tinymce/plugins/textpattern' // 快速排版
// import 'tinymce/plugins/toc' // 目录生成器
import 'tinymce/plugins/visualblocks' // 显示元素范围
import 'tinymce/plugins/visualchars' // 显示不可见字符
import 'tinymce/plugins/wordcount' // 字数统计
// import 'tinymce/plugins/importword' // 导入word
// import 'tinymce/plugins/axupimgs/plugin' // 多图上传

// import axios from 'axios'

import photoGallerySelect from './components/photoGallery'
import iconGallerySelect from './components/iconGallery'
export default {
  name: 'Tinymce',
  components: {
    Editor,
    photoGallerySelect,
    iconGallerySelect
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [
          // ' | cut copy paste'
          'code undo redo restoredraft | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent |',
          'formatselect fontselect fontsizeselect | bullist numlist | subscript superscript removeformat |',
          'table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | lineheight axupimgs importword'
        ]
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 400
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    picmaxsize: {
      type: Number,
      required: false,
      default: 10
    },
    typesetting: {
      type: Boolean,
      required: false,
      default: true
    },
    photoGallery: {
      type: Boolean,
      required: false,
      default: false
    },
    iconGallery: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      imgData: "",
      fullscreen: false,
      init: {
        height: this.height, // 插件高度
        toolbar: this.toolbar, // 工具栏
        menubar: this.menubar, // 菜单栏
        base_url: './',
        language_url: 'tinymce/langs/zh_CN.js',
        language:'zh_CN',
        // language: localStorage.getItem('language')== 'zh'?'zh_CN':'',
        content_css: false,
        skin_url: 'tinymce/skins/ui/oxide',
        content_style: 'img {max-width:100%;}',
        plugins: [
          // bbcode 会替换掉原本的html标签 ；fullpage ：会加上html body等标签。 autosave:自动保存,
          'advlist anchor autolink charmap code directionality emoticons',
          'fullscreen help hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable',
          'pagebreak preview print quickbars save searchreplace spellchecker tabfocus table template textpattern',
          'visualblocks visualchars wordcount importword axupimgs'
        ],
        emoticons_database_url: '/js/emoji.js', // 表情包路径
        font_formats: `
        楷体=KaiTi,KaiTi_GB2312,DFKai-SB,STKaiti,sans-serif;
        微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;
        苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;
        宋体=simsun,serif;
        仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;
        Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;
        Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;
        Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;
        Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;
        Webdings=webdings;Wingdings=wingdings,zapf dingbats;
        知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
        小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif`, // 自定义字体
        fontsize_formats: '12px 14px 16px 18px 24px 36px',
        elementpath: false, // html标签路径
        paste_data_images: true, // 粘贴图片
        auto_focus: false, // 自动获得焦点
        quickbars_selection_toolbar: 'bold italic | link forecolor backcolor', // 选中文字时快捷菜单
        end_container_on_empty_block: true, // 回车时分割当前块
        branding: false, // 右下角技术支持
        // powerpaste_word_import: 'clean', // 粘贴word
        advlist_bullet_styles: 'square', // 列表 bullet 默认样式
        advlist_number_styles: 'default', // 列表 number 默认样式
        default_link_target: '_blank', // 默认链接打开方式
        link_title: false, // 链接标题
        nonbreaking_force_tab: true, // 按下tab键时强制插入3个&nbsp;

        // 图片相关配置
        image_advtab: true,
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io', 'fuss10.elemecdn.com', 'www.xinhuanet.com'], // 允许图片跨域
        imagetools_proxy: 'proxy.php', // 如果无法配置跨域图片host，则用后端代理请求图片后返回
        images_upload_url: '/httpServe/clientHelp/fileUp', // 图片上传服务器接口地址
        images_upload_base_path: '/httpServe/clientHelp/fileUp', // 图片回调url的相对路径，可写可不写，为兼容图片回调而设。

        /** *************** 插件钩子 *************** **/
        // 图片上传
        images_upload_handler: (blobInfo, success, failFun) => {
          console.log(blobInfo.blob());
          let formdata = new FormData();
          // formdata.append("imgData", blobInfo.base64());
          formdata.append("files", blobInfo.blob());
          formdata.append("type", "protected");
          formdata.append("fileType", "img");
          formdata.append("moduleName", "protected");
          this.uploads(formdata);
          const b64 = blobInfo.base64();
          success("data:image/png;base64," + b64);
        },
        init_instance_callback: (editor) => {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
        },
        setup(editor) {
          editor.on("FullscreenStateChanged", (e) => {
            _this.fullscreen = e.state;
          });
        }
      },
      myValue: this.value,
      mytinymce: {}
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  },
  mounted() {
    tinymce.init({})
    this.mytinymce = tinymce.editors[tinymce.editors.length - 1]
  },
  methods: {
    async uploads(form) {
      const res = await this.$axios.upload(
        "/httpServe/sysFile/fileUp",
        form,
        this.updateProgress,
        true
      );
      this.imgData = res.data.data;
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },
    setContent(value) {
      this.mytinymce.setContent(value)
    },
    insertContent(value) {
      this.mytinymce.insertContent(value)
    },
    handlerTypesetting() {
      this.myValue = this.formatHtml(this.value)
    },
    photoGallerySelected(arr) {
      arr.forEach(v => this.insertContent(`<img src="${v}" >`))
    },
    iconGallerySelected(url) {
      this.insertContent(`<img src="${url}" >`)
    },
    // 一键排版
    formatHtml(txt) {
      txt = txt.replace(/&nbsp;/ig, '')

      // 清除所有空行
      var arrPattern = [
        '<p(>|\\s+[^>]*>)(&nbsp|&nbsp;|\\s|　|<br\\s*(\/)?>)*<\/p(>|\\s+[^>]*>)',
        '(<br\\s*(\/)?>((\\s|&nbsp;|&nbsp|　)*)){2,}',
        '(<p(>|\\s+[^>]*>))((&nbsp|&nbsp;|\\s)*<br\\s*(\/)?>)*((.|\n|\r)*?<\/p(>|\\s+[^>]*>))'
      ]
      var arrReplace = ['', '<br />$3', '$1$6']
      for (var i = 0; i < arrPattern.length; i++) {
        var arrRegExp = new RegExp(arrPattern[i], 'img')
        txt = txt.replace(arrRegExp, arrReplace[i])
      }

      // 清除所有非空格的空白字符，空格字符去除可能导致元素属性出错
      txt = txt.replace(/[\f\n\r\t\v]/ig, '')
      txt = txt.replace(/\u3000/ig, '')// 全角空格

      // 清除所有span、strong、em、u、blockquote、strike、i、b、sub、sup
      txt = txt.replace(/<(\/?span|strong|em|u|blockquote|strike|i|b|sub|sup|font|s)\s+?.*?>/ig, '')
      txt = txt.replace(/<(\/?span|strong|em|u|blockquote|strike|i|b|sub|sup|font|s)[^mg\s]*?>/ig, '')

      // 替换所有h标签为p标签
      txt = txt.replace(/(<\/?)h\d.*?>/ig, '$1p>')

      // 实际运行中发现有些错误的a链接可能根本没有href，所以需要再次清理
      // txt = txt.replace(/<(\/a|a).*?>/ig, '')

      // 清除所有class，为防止有些class不加引号，因此强制规定只清除元素内的class --有bug
      // txt = txt.replace(/<([a-zA-Z1-6]+)(.*?)\s*class\s*=\s*[\"|\']?.*?[\"|\']\s*(.*?)>?/ig, '<$1 $2 $3>')

      // 清除所有style属性
      txt = txt.replace(/style\s*?=\s*?([\'\"])[\s\S]*?\1/ig, '')
      // RegExp对象语法，可用于自定义变量，比如出了style以外的class，lang等等
      var v = 'style'
      txt = txt.replace(new RegExp(v + "\\s*?=\\s*?([\'\"])[\\s\\S]*?\\1", 'ig'), '')

      // 去掉除img p标签之外的标签
      // txt = txt.replace(/<(?!img|p|\/p).*?>/g, '')

      // 给每段加上样式
      const formatStyle = 'font-size: 16px;line-height: 1.4;font-family:Microsoft YaHei'
      txt = txt.replace(/<p(?![^>]+?class="img_describe_flag")[^>]*?>/ig, `<p  style="text-indent: 2em;${formatStyle}">`)
      txt = txt.replace(/<p(?=[^>]+?class="img_describe_flag")[^>]*?>/ig, `<p class="img_describe_flag" style="text-align: center;${formatStyle}" >`)

      txt = txt.replace(/(<td.*?>[\D\W\s]*?)<p.*?>/ig, '$1<p>')
      txt = txt.replace(/<p[^>]*?>(?=<img)/ig, '<p>')

      // 给img标签限制宽度,居中
      txt = txt.replace(/<img([^>]*?)>/g, '<img style="max-width: 100%; display: block; margin-left: auto; margin-right: auto;" $1>')
      // 图片加一行描述文字
      txt = txt.replace(/(<p[^>]*?><img[^>]*?><\/p>)(?![^>]*?<p[^>]*?(img_describe_flag)[^>]*?>)/g, `$1<p class="img_describe_flag" style="text-align: center;${formatStyle}" >图片描述</p>`)

      // 给table标签限制宽度
      txt = txt.replace(/<table([^>]*?)>/g, '<table style="width:100%;border-collapse: collapse;" $1>')
      // 去掉table标签里p标签的样式
      const tableStrArr = txt.match(/<table[^>]*?>[\s\S]*?<\/table>/g)
      if (tableStrArr) {
        const txtcache = txt.replace(/<table[^>]*?>[\s\S]*?<\/table>/g, '|||_|||')
        const noregexStr = txtcache.split('|||_|||')
        let resultStr = ''
        for (let i = 0; i < noregexStr.length; i++) {
          const element = noregexStr[i]
          let tableStr = tableStrArr[i]
          if (tableStr) {
            tableStr = tableStr.replace(/<p[^>]+?>([\s\S]*?<\/p>)/g, '<p>$1')
            resultStr += element + tableStr
          } else {
            resultStr += element
          }
        }

        return resultStr
      }
      return txt
    },
    // 压缩图片
    dealImage(path, obj, callback) {
      var img = new Image()
      img.src = path
      img.onload = function() {
        var that = this
        // 默认按比例压缩
        var w = that.width
        var h = that.height
        var scale = w / h
        w = obj.width || w
        h = obj.height || (w / scale)
        var quality = 0.6 // 默认图片质量为0.6
        // 生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        // 创建属性节点
        var anw = document.createAttribute('width')
        anw.nodeValue = w
        var anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    convertBase64UrlToBlob(urlData) {
      // 去掉url的头，并转换为byte
      var bytes = window.atob(urlData.split(',')[1])
      // 处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/png' })
    }
  }
}
</script>

<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: auto;
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
.tox .tox-editor-header{
  z-index: 0 !important;
}
</style>
<style>
.tox .tox-editor-header{
  z-index: 0 !important;
}
.tox .tox-button {
  background-color: #1e89e5 !important;
}
.tox .tox-dialog__header .tox-button {
  color: #fff !important;
}
</style>