<template>
  <div>
    <el-button type="primary" size="mini" class="editor-typesetting" @click="dialogVisible=true">
      默认选择图片
    </el-button>
    <el-dialog :visible.sync="dialogVisible">
      <div class="main">
        <div class="body">
          <div v-for="item in photoList" :key="item.id" class="pic-item">
            <el-checkbox v-model="photoSelected" :label="item.id" class="photogallery">
              <el-image :src="item.url" />
            </el-checkbox>
          </div>
        </div>
        <div class="foot">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="comfirm">确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      photoSelected: [],
      photoList: [
        { id: 1, name: '图片1', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 2, name: '图片2', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F052420110515%2F200524110515-2-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=fe324f2b6bfab1ac8bc8c7154d040972' },
        { id: 3, name: '图片3', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F052420110515%2F200524110515-11-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=78311304ff2adb2739295f7b90286b57' },
        { id: 4, name: '图片4', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi-1.lanrentuku.com%2F2020%2F7%2F3%2Fc428aa47-0933-433a-be80-3d4ec5a89ec7.jpg&refer=http%3A%2F%2Fi-1.lanrentuku.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=f5d42fa6a4c296889a928ec53644785d' },
        { id: 5, name: '图片5', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.jj20.com%2Fup%2Fallimg%2F1111%2F09291Q50950%2F1P929150950-8.jpg&refer=http%3A%2F%2Fpic.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653102298&t=b19f3aba01905ba74f6cc6c4e5a660e3' },
        { id: 6, name: '图片6', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 7, name: '图片7', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 8, name: '图片8', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 9, name: '图片9', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 10, name: '图片10', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 11, name: '图片11', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 12, name: '图片12', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 13, name: '图片13', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 14, name: '图片14', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 15, name: '图片15', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 16, name: '图片16', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 17, name: '图片17', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 18, name: '图片18', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 19, name: '图片19', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 20, name: '图片20', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' },
        { id: 21, name: '图片21', url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653101939&t=3a576f7168142a8e543be76ba22345a5' }
      ]
    }
  },
  methods: {
    comfirm() {
      const _this = this
      const selected = this.photoList.filter(p => _this.photoSelected.indexOf(p.id) > -1).map(s => s.url)
      this.$emit('selected', selected)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  .body{
    height: 450px;
    overflow: auto;
    .pic-item{
      display: inline-block;
      width:20%;
      padding: 5px;
      img{
        width:100%;
        height:100%;
      }
    }
  }

  .foot{
    text-align: right;
    padding-top: 10px;
  }
}

.photogallery{
  position: relative;
  ::v-deep.el-checkbox__input{
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
